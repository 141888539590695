import * as React from "react"
import { graphql } from "gatsby"
import slugify from "slugify"

import { Layout, Breadcrumb, ServiceDetails, Seo } from "../components"

const OfferPage = ({ data }) => {
  const { frontmatter: content } = data.content

  return (
    <Layout>
      <Seo title="Oferta" />

      <div className="active-dark">
        <Breadcrumb
          title="Oferta"
          background={content.background}
          backgroundMobile={content.backgroundMobile}
        />

        {content.services.map((service, index) => (
          <ServiceDetails
            key={slugify(service.title)}
            title={service.title}
            content={service.content}
            image={service.image}
            imageSide={index % 2 === 0 ? "left" : "right"}
            ctaButtonText={content.ctaButton}
            brandSeparation={content.services.length === index + 1}
          />
        ))}
      </div>
    </Layout>
  )
}

export const guery = graphql`
  {
    content: markdownRemark(
      fields: { collection: { eq: "pages" }, slug: { eq: "offer" } }
    ) {
      frontmatter {
        background
        backgroundMobile
        ctaButton
        services {
          title
          content
          image
        }
      }
    }
  }
`

export default OfferPage
